@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.NavBar {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-weight: bold;
    background-color: #6fd1f5;
    border-top-color: #0e1428;
    padding-bottom: 0px;
    margin-bottom: 50px;
    border-bottom: 6px solid #2a3339;
}

nav {
    margin: 0 auto;
    text-align: center;
    background-color: #6fd1f5;
}

.NavBar {
    padding-left: 15%;
    padding-right: 15%;
}

.NavBar ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    padding-left: 0;
}

.NavBar ul li {
    text-align: center;
    list-style: none;
    flex: 0 0 25%;
}

@media only screen and (max-width: 768px) {
    .NavBar {
            padding-left: 5%;
            padding-right: 5%;
        }
    
    .NavBar ul li {
        font-size: 12px;
    }

    .NavBar li a {
        padding: 10px 10px;
    }
}


.NavBar li a {
  display: block;
  color: rgba(59, 71, 79, 0.92);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.NavBar li a:hover {
    text-decoration: underline;
}

.Email {
    font-weight: 600;
    margin: 0px;
}

.profile {
    background-color: #6fd1f5;
    text-align: center;
    min-height: 100px;
    padding: 20px;
    padding-bottom: 0px;
    text-decoration: none;

    h1 {
        font-size: 36px;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        color: #2a3339;
    }
    
    img {
        border-radius: 50%;
        max-width: 100%;
        max-height: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;        
    }
    
    .profile-picture {
        margin-bottom: 10px;
        height: 150px;
    }

    a,
    a:hover,
    a:visited {
        color: rgba(59, 71, 79, 0.92);
    }
}

.card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    
    a:hover {
        text-decoration: none;
    }
}

@media only screen and (max-width: 1500px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1000px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .card-container {
    grid-template-columns: 1fr;
  }
}


body {
    margin-bottom: 50px;
}

h2 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: 700;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.card {
    align-items: center;
    margin: 20px 30px;
    background-color: white;
    border-radius: .375rem;
    padding: 1.5em;
    font-size: 1.2em;
    display: grid;
    grid-template-columns: 70px auto;
    grid-column-gap: 20px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: box-shadow .3s;
}

.card:hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 10px 10px rgba(0,0,0,0.23);
}

.CV {
    background-color: #6fd1f5;
    border-radius: .375rem;
        border-style: none;
        box-sizing: border-box;
        color: #FFFFFF;
        cursor: pointer;
        display: inline-block;
        font-size: 24px;
        font-weight: 500;
        height: 40px;
        line-height: 20px;
        list-style: none;
        margin: 0;
        outline: none;
        padding: 10px 16px;
        margin-bottom: 20px;
        position: relative;
        text-align: center;
        text-decoration: none;
        transition: color 100ms;
        vertical-align: baseline;
        user-select: none;
        touch-action: manipulation;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.CV a {
    color: white;
}

.Contact {
    background-color: #6fd1f5;
    border-radius: .375rem;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 24px;
    font-weight: 500;
    height: 40px;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    touch-action: manipulation;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Contact::hover {
    background-color: #0e1428;
}
.Bio {
    margin-left: 10%;
    margin-right: 10%;
}

.Bio a {
    color: #6fd1f5;
}

.Bio a:hover { 
    color: #6fd1f5;
    text-decoration: underline;
}

.Bio p {
    font-size: large;
}

.BigClients {
    align-items: center;
    margin-top: 0px;
}

.BigClients img {
    width: 200px;
    height: 200px;
    object-fit: scale-down;
    margin: 25px;
}

.Clients {
    align-items: center;
}

.Clients img {
    width: 100px;
    height: 100px;
    object-fit: scale-down;
    margin: 25px;
}

.Videos iframe {
    margin: 5px;
}

.Blogs iframe {
    margin: 5px;
}

.EventPhotos img {
    height: 315px;
    margin: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    
}

a {
    color: black;
    text-decoration: none;
}

a:hover {
    color: #363636;
    text-decoration: underline;
}

.info {
    p {
        margin-top: 5px;
        margin: 0px;
        text-align: left;
    }

    .title {
        font-size: 16px;
        font-weight: bold;
    }

    .location {
        font-size: medium;
        font-weight: 400;
    }

    .dates {
        font-size: small;
        opacity: 0.8;
    }
}

img {
    max-width: 100%;
}

.card {
    /**
    Global styles and utilities for cards
     */
    @mixin lightText($blow-out: true) {
        color: white;

        @if ($blow-out) {
            img {
                filter: brightness(100);
            }
        }
    }

    @mixin darkText($crush: true) {
        color: #212529;

        @if ($crush) {
            img {
                filter: brightness(0);
            }
        }
    }
    a {
        
    }
    /**
    Work Experience
     */

    &.kestra {
        background: #4b0aaa;
        @include lightText($blow-out: false);
    }

    &.fellowship {
        background: #1e539f;
        @include lightText($blow-out: false);
    }

    &.hp {
        background: #212529;
        @include lightText;
    }

    &.hc {
        background: #07b9e8;
        @include darkText;
    }

    &.mlh {
        //background: #e73427; // Red
        //background: #f8b92a; // Yellow
        //background: #1d539f; // Blue
        background: #ca1508; // Dark red (not an official brand colour but required for legibility)
        @include lightText;
    }

    &.arm {
        background: #11809f;
        @include lightText;
    }

    /**
    Education
     */
    &.uob {
        background: #605270;
        @include lightText($blow-out: false);
    }
    
    &.hbk {
        background: #164a25;
        @include lightText($blow-out: false);
    }

    &.huk {
        background: #e91e63;
        @include lightText;
    }

    &.htm {
        background: #449afd;
        @include lightText;
    }

    &.hq {
        background: #0e1428;
        @include lightText($blow-out: false);
    }

    &.h4s {
        background: #5c47ff;
        @include lightText($blow-out: false);
    }

    &.rs600 {
        background: #fff;
    }

    &.gwsc {
        background: #fff
    }

    &.st60 {
        background: #36a3f7;
        @include lightText;
    }
}
